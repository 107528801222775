





























import Vue from "vue";
import { mapGetters } from "vuex";
import { USER_STATE } from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";

export default Vue.extend({
  name: "NotFound",
  computed: {
    UserState() {
      return UserState;
    },
    ...mapGetters("auth", {
      get_user_state: USER_STATE
    })
  },
  methods: {
    async navigation_based_on_state() {
      switch (this.get_user_state) {
        case UserState.RECRUITER:
          await this.$router.push("/recruiter/dashboard");
          break;
        case UserState.RECRUITER_ADMIN:
          await this.$router.push("/recruiter/dashboard");
          break;
        case UserState.CANDIDATE:
          await this.$router.push("/candidate/dashboard");
          break;
        case UserState.UNAUTHENTICATED:
          await this.$router.push("/auth/login");
          break;
        default:
          window.location.href = "/";
      }
    }
  }
});
